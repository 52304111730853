<template>
  <div v-if="order">
    <h1>Заказ №{{ order.code }} ({{ order.id }})
    </h1>
    <CRow>
      <CCol sm="12" md="8" class="order-md-0 order-1">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-justify-center"/>
            <strong> Состав заказа </strong>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :items="order.purchases"
              :fields="columns"
              :bordered="true"
              :striped="true"
              class="text-center"
            >
              <template #price="{item}">
                <td>
                  {{ item.price }} руб.
                </td>
              </template>
              <template #image="{item}">
                <td>
                  <CImg :src="item.image" class="border"></CImg>
                </td>
              </template>
            </CDataTable>
            <CRow class="font-xl">
              <CCol xl="1">
              </CCol>
              <CCol xl="11" class="text-right">
                Всего: <strong>{{ fullPrice }} руб.</strong>
              </CCol>
            </CRow>
            <hr>
            <CRow class="font-xl">
              <CCol xl="6">
                Скидка: <strong>{{ order.price.discount }}%</strong>
              </CCol>
              <CCol xl="6" class="text-right">
                С учетом скидки: <strong>{{ withDiscountPrice }} руб.</strong>
              </CCol>
            </CRow>
            <hr>
            <CRow class="font-xl">
              <CCol xl="6">
                Купон: <strong>{{ order.price.coupon_discount }}%</strong>
              </CCol>
              <CCol xl="6" class="text-right">
                С учетом купона: <strong>{{ withCouponPrice }} руб.</strong>
              </CCol>
            </CRow>
            <hr>
            <CRow>
              <CCol xl="8">
                <CRow>
                  <CCol xl="12" class="font-xl">
                    Доставка: <strong>{{ order.price.delivery }} руб.</strong>
                  </CCol>
                  <CCol xl="12">
                    Курьер:<br>
                    <strong>{{ order.courier.name }} &mdash; {{ order.courier.phone }}</strong>
                  </CCol>
                </CRow>
              </CCol>
              <CCol xl="4" class="text-right font-xl">
                Всего: <strong>{{ order.price.total }} руб.</strong>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="12" md="4" class="order-md-1 order-0">
        <CCard v-if="order.status !== 2 && order.status !== 3">
          <CCardHeader>
            <CIcon name="cil-justify-center"/>
            <strong> Действия </strong>
          </CCardHeader>
          <CCardBody class="text-center">
            <CCol sm="12" class="pb-2">
              <CButton color="success" :disabled="buttons.done" @click="deliveryDoneOpenModal()">
                <CIcon name="cil-check-circle"></CIcon>
                Заказ доставлен
              </CButton>
            </CCol>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader v-if="order">
            <CIcon name="cil-justify-center"/>
            <strong> Детали заказа </strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Статус:</div>
                <CBadge class="font-lg" :color="statuses[order.status].badge">{{ statuses[order.status].name }}</CBadge>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Дата заказа:</div>
                <div class="font-lg">{{ order.created_at }}</div>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Имя покупателя:</div>
                <div class="font-lg">{{ order.consumer.name }}</div>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Email покупателя:</div>
                <div class="font-lg">{{ order.consumer.email }}</div>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Телефон покупателя:</div>
                <div class="font-lg">{{ order.consumer.phone }}</div>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Адрес получателя:</div>
                <div class="font-lg">{{ order.delivery.address }}</div>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Имя получателя:</div>
                <div class="font-lg">{{ order.recipient.name }}</div>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Телефон получателя:</div>
                <div class="font-lg">{{ order.recipient.phone }}</div>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">В открытке написать:</div>
                <div class="font-lg">{{ order.postcard.text }}</div>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Подпись:</div>
                <div class="font-lg">{{ order.postcard.signature }}</div>
              </CCol>
              <CCol sm="12" class="pb-2">
                <div class="text-muted font-sm font-weight-bold">Комментарий:</div>
                <div class="font-lg">{{ order.delivery.comment }}</div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Вы уверены?"
      size="sm"
      :centered=true
      :show.sync="deliveryDoneModal"
      @close="deliveryDoneCloseModal()"
    >
      <template #body-wrapper><div></div></template>
      <template #footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="deliveryDone()"
        >
          Да
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="deliveryDoneCloseModal()"
        >
          Отмена
        </button>
      </template>
    </CModal>
  </div>
  <div v-else>
    <CRow class="mb-4">
      <CCol class="p-3 align-content-center align-items-center text-center">
        <CSpinner :grow="true" size="lg" color="primary"></CSpinner>
        Загрузка...
      </CCol>
    </CRow>
  </div>
</template>

<script>
import statuses from './order_statuses'

export default {
  name: 'DeliveryView',
  data() {
    return {
      deliveryDoneModal: false,
      order: null,
      fullPrice: 0,
      withDiscountPrice: 0,
      withCouponPrice: 0,
      withDeliveryPrice: 0,
      statuses: statuses,
      columns: [
        {
          key: 'image',
          label: 'Фото'
        },
        {
          key: 'product_name',
          label: 'Название'
        },
        {
          key: 'variant_name',
          label: 'Вариант'
        },
        {
          key: 'price',
          label: 'Цена'
        },
        {
          key: 'amount',
          label: 'Кол-во'
        },
      ],
      buttons: {
        done: false,
      },
    }
  },
  mounted() {
    this.loadDelivery()
  },
  methods: {
    async loadDelivery() {
      let id = this.$route.params.id;
      let result = await this.$http.get('/deliveries/view/' + id);
      this.order = result.data.data;
      this.fullPrice = 0;
      for (let purchase of this.order.purchases) {
        this.fullPrice += purchase.amount * purchase.price;
      }
      this.withDiscountPrice = this.fullPrice - ((this.fullPrice * this.order.price.discount) / 100);
      this.withCouponPrice = Math.round(this.withDiscountPrice - ((this.withDiscountPrice * this.order.price.coupon_discount) / 100));
      this.withDeliveryPrice = this.withCouponPrice + this.order.price.delivery;
      console.log(result.data.data);
    },
    deliveryDoneOpenModal() {
      this.deliveryDoneModal = true;
    },
    deliveryDoneCloseModal() {
      this.deliveryDoneModal = false;
    },
    async deliveryDone() {
      this.buttons.done = true;
      this.deliveryDoneCloseModal();

      await this.$http.post('/deliveries/delivered/' + this.order.id);

      this.$store.commit('decrementCountDeliveries');
      await this.loadDelivery();

      this.buttons.done = false;
    },
  }
}
</script>
